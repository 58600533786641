.swiper-custom {
  width: 100%;
}

.swiper-custom-left {
  right: 0px !important;
}

.swiper-custom-right {
  right: -40px;
}

.custom-corusel-slide {
  // width: 280px !important;
}
