.modal-container {
  position: relative;
}

.modal-cart {
  position: absolute;
  z-index: 100000;
  background-color: black;
  color: rgb(16, 159, 0);
  padding: 5px 20px 5px 20px;
  right: 0px;
  top: 10px;
  font-size: 24px;
  border: 1px solid rgb(16, 159, 0);
  border-radius: 4px;
}
