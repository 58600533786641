.pay-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 0px 20px;
  margin: 20px 0px 20px 0px;
}

.pay-button-form {
  padding: 10px 20px 10px 20px;
  background-color: rgba(var(--b-sc-rgb), 0.15);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.pay-button-text {
  color: white;
  transition: background-color 0.3s;
  cursor: pointer;
}

.pay-button-text:active {
  color: rgb(16, 1, 58);
}

.pay-button-form:active {
  background-color: #2980b9;
}

.active-bonus-container {
  position: relative;

  .active-bonus-remove {
    width: 50px;
    height: 50px;
    background-color: red; // Цвет или стиль вашей кнопки удаления
    // Добавьте другие стили по вашему усмотрению
  }
}

// Стили для модального окна

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  display: none; // По умолчанию модальное окно скрыто
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;

  p {
    margin-bottom: 20px;
  }

  button {
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  button:first-child {
    background-color: #4caf50; // Цвет кнопки "Да"
    color: #fff;
  }

  button:last-child {
    background-color: #f44336; // Цвет кнопки "Нет"
    color: #fff;
  }
}
