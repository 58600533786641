
.layout-content-holder-bc {
  grid-area: content;
  width: 100%;
  box-sizing: border-box;
}

.layout-bc.page-full-size .layout-content-holder-bc {
  padding: 0 10px
}

.layout-bc.slider-full-sized .layout-content-holder-bc,.layout-bc.sportsbook .layout-content-holder-bc {
  -webkit-padding-start: 10px;
  padding-inline-start:10px
}

.layout-bc.is-home-page {


  .hm-row-bc {
    grid-template-columns: 12fr;
    &.has-slider {
      position: relative;
    }
    &.jackpot-banners-row {
      grid-template-columns: 6fr 6fr;
    }
  }

  .jackpot-banners-row {
    .carousel-container {
      transform: translate3d(0%, 0px, 0px);
      .carousel-slide {
        width: 100%;
      }
    }
  }
}

