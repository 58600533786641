.chat-inbox-container {
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 height: 100%;
}

.message-info-container {
 display: flex;
 flex-direction: row;
 flex-wrap: wrap;
}

.messageTitleInfo {
 color: white;
 font-size: 14px;
 margin-top: 20px;
 margin-left: 30px;
}

.contnet-container {
 width: 100%;
 height: 100%;
 display: flex;
 justify-content: center;
 align-items: center;
 padding: 20px;
 position: relative;
}

.content-wrapper {
 position: relative;
 width: 100%;
 height: 100%;
 background-color: rgba(11, 11, 67, 0.547);
 overflow-y: scroll;
 display: flex;
 flex-direction: column;
 padding-bottom: 40px;
}

.message-info-contnainer {
 display: flex;
 flex-direction: column;
 margin-top: 20px;
 &.sent {
  align-items: end;
  margin-right: 20px;
 }
 &.inbox {
  align-items: start;
  margin-left: 20px;
 }
}

.message-text {
 background-color: rgba(11, 11, 58, 0.637);
 color: white;
 padding: 20px 40px;
 font-size: 14px;
 max-width: 70%;
 min-width: 70%;
 border: 1px solid rgba(255, 255, 255, 0.292);
 border-radius: 10px;
 margin-bottom: 10px;
}

.message-date {
 color: rgba(255, 255, 255, 0.7);
 font-size: 14px;
}

.chat-input-container {
 display: flex;
 flex-direction: row;
 align-items: center;
 margin: 20px;
}

.chat-input {
 width: 80%;
 margin-right: 20px;
 border: 2px solid rgba(0, 0, 0, 0.423);
 padding: 10px;
 color: white;
 font-size: 14px;
}

.chat-send-button {
 color: white;
 text-align: center;
 height: 100%;
 background-color: black;
 width: 10%;
 border-radius: 10px;
}
