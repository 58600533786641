.custom-tab-nav-item-bc {
  margin-top: 2px;
}

.custom-tab-nav-title-bc {
  margin-top: 4px;
}

.custom-footer-contrainer {
  background: #1f2e48 !important
}

.custom-footer-color {
  color: white;
}
