.table-container {
  background-color: #1f1f1f;
  padding: 20px;
  border-radius: 8px;
  color: #ddd;
  overflow-x: scroll;

  .custom-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    font-size: 14px;
    color: #ddd;

    .table-header {
      background-color: #2a2a2a;

      .header-cell {
        padding: 12px;
        text-align: left;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .table-body {
      .table-row {
        &:nth-child(odd) {
          background-color: #252525;
        }
        &:nth-child(even) {
          background-color: #2a2a2a;
        }
        &:hover {
          background-color: #333;
        }

        .table-cell {
          padding: 12px;
          border-bottom: 1px solid #3a3a3a;
        }
      }

      .details-row {
        background-color: #292929;

        .details-table {
          width: 100%;
          border-collapse: collapse;
          font-size: 13px;
          color: #bbb;

          th, td {
            padding: 8px;
            border-bottom: 1px solid #3a3a3a;
          }

          th {
            text-align: left;
            font-weight: bold;
          }
        }
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pagination-button {
      background-color: #3a3a3a;
      color: #fff;
      border: none;
      padding: 8px 16px;
      font-size: 14px;
      cursor: pointer;
      transition: background-color 0.3s;
      border-radius: 4px;

      &:disabled {
        background-color: #555;
        cursor: not-allowed;
      }

      &:hover:not(:disabled) {
        background-color: #555;
      }
    }

    .pagination-info {
      font-size: 14px;
    }
  }
}
