.date-picker-container {
  position: relative;
}

.right-arrow-custom-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.left-arrow-custom-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.arrow-custom {
  color: rgba(var(--b-sc-rgb), 0.3);
}

.custom-datepicker {
  padding-bottom: 16px;
}
