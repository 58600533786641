.card {
  display: flex;
  width: 100%;
  margin-top: 10px;
  padding: 15px 30px 40px 15px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;

  .left {
    flex: 1;
    padding-right: 10px;
  }

  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    color: white;
    margin-bottom: 10px;
  }

  .date {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.7);
  }

  .status {
    font-size: 20px;
    &.cancaled {
      color: rgb(251 113 133 / 1);
    }
    &.completed {
      color: rgb(74 222 128 / 1);
    }
  }

  .amount {
    font-size: 16px;
    color: white;
  }
}
